import React from 'react'
import Helmet from 'react-helmet'
import _ from 'lodash'

import { graphql } from 'gatsby'

import BarTile from '../components/BarTile'

import Layout from '../layouts'
import LocalityBreadCrumb from '../components/LocalityBreadCrumb'

const MAX_N_BARS = 10

function argMin(array) {
  return array.map((x, i) => [x, i])
    .reduce((r, a) => (_.isNumber(a[0]) && (a[0] < r[0]) ? a : r))[1]
}

const mergeBars = (data) => {
  const barTypes = _.keys(data)

  const barByTypes = _.chain(data)
    .at(barTypes)
    .map('edges')
    .map((barType) => (barType ? barType.map((bar) => bar.node) : []))
    .value()

  const cheapestBars = []
  const selectedBarIds = new Set()

  const counters = Array(barByTypes.length).fill(0)

  const extractBarPrice = (bar, i) => {
    if (!bar) return undefined
    return barTypes[i].includes('regular') ? bar.regularPrice : bar.happyHourPrice
  }

  while (cheapestBars.length < MAX_N_BARS) {
    const currentBars = barByTypes.map((bars, i) => bars[counters[i]])

    const prices = currentBars.map(extractBarPrice)

    const selectedBarIndex = argMin(prices)
    const selectedBar = currentBars[selectedBarIndex]

    counters[selectedBarIndex] += 1

    if (!selectedBar) break

    if (!selectedBarIds.has(selectedBar.id)) {
      selectedBarIds.add(selectedBar.id)
      cheapestBars.push(selectedBar)
    }
  }

  return cheapestBars
}

const TopBars = ({ bars }) => (
  <div className="columns is-multiline">
    {bars.map((bar) => <BarTile {...bar} key={bar.id} />)}
  </div>
)

const NoBars = ({ city }) => (
  <p>
    {`Il n'y a pas encore de bar à ${city}, soyez le
            premier à en ajouter !`}
  </p>
)

const CityDescription = ({ description }) => (
  <div className="content">
    {description}
  </div>
)

const Breweries = ({ city, breweries }) => {
  if (breweries) {
    const plural = breweries.length > 1
    return (
      <div className="content">
        <p>
          {`Il y a ${breweries.length} brasserie${plural ? 's' : ''}
            à ${city} qui brasse${plural ? 'nt leur' : ' sa'} propre bière localement.
            Il s'agit de`}
        </p>
        <ul>
          {breweries.map((brewery) => <li>{brewery}</li>)}
        </ul>
      </div>
    )
  }
  return undefined
}


const CheapestBarsInCity = ({ data, pathContext }) => {
  const mergedBars = mergeBars(data)

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <Helmet title={`Les bars les moins chers de ${pathContext.city}`} />
          <LocalityBreadCrumb
            region={pathContext.region}
            department={pathContext.department}
            cityName={pathContext.city}
          />
          <h1 className="title">
            {`Les ${mergedBars.length} bars les moins chers de ${pathContext.city}`}
          </h1>
          {mergedBars.length > 0
            ? <TopBars bars={mergedBars} />
            : <NoBars city={pathContext.city} />}
          <CityDescription
            description={data.description && data.description.edges[0]
              ? data.description.edges[0].node.description : ''}
          />
          <Breweries city={pathContext.city} breweries={pathContext.breweries} />
        </div>
      </section>
    </Layout>
  )
}

export default CheapestBarsInCity


export const pageQuery = graphql`
  query BarsByZone ($city: String!, $postalCode: String!) {
    regularPriceBarsVincinity : allMisterGoodBeerBar (
      filter: {
        address_details: {vicinity: {eq: $city}},
      },
      limit: 10,
      sort: {order: ASC, fields: [regularPrice]}
      )
    {
      edges {
        ...barFragment
      }
    }

    regularPriceBarsPostalCode : allMisterGoodBeerBar (
      filter: {
        address_details: {postalCode: {eq: $postalCode}},
      },
      limit: 10,
      sort: {order: ASC, fields: [regularPrice]}
      )
    {
      edges {
        ...barFragment
      }
    }

    happyHourPriceBarsVincinity : allMisterGoodBeerBar (
      filter: {
        address_details: {vicinity: {eq: $city}},
      },
      limit: 10,
      sort: {order: ASC, fields: [happyHourPrice]}
      )
    {
      edges {
        ...barFragment
      }
    }

    happyHourPriceBarsPostalCode : allMisterGoodBeerBar (
      filter: {
        address_details: {postalCode: {eq: $postalCode}},
      },
      limit: 10,
      sort: {order: ASC, fields: [happyHourPrice]}
      )
    {
      edges {
        ...barFragment
      }
    }
    
    description: allCityDescriptionsJson(filter: {city: {eq: $city}}) {
      edges {
        node {
          description
        }
      }
    }
  }

  fragment barFragment on MisterGoodBeerBarEdge {
    node {
      id
      slug
      name
      address
      regularPrice
      happyHourPrice
      startHappyHour { iso }
      endHappyHour { iso }
    }
  }
`
